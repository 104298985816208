<template>
    <div class = "PMCompare">
      
    <div id = "header"> Mai's Power Meter Comparison Tool</div>
        <div id = "nav">
            <div id = "option">
                <div id = "try_demo" @click="try_demo()" style="cursor: pointer;">TRY DEMO</div>
                <div id = "about" @click="aboutShow = true" style="cursor: pointer;">ABOUT</div>
            </div>
        </div>
    </div>

    <div id="mai_logo_container">
        <router-link to= "/">
            <img src="/images/MaiLogo.png" id="mai_logo" >
        </router-link>
    </div>
    
    <div id = "upload" class = "drag">
        <form id = "postFit" @submit.prevent="handleFileSubmit">
                <input ref="upload_file" id = "upload_file" @change="changeFileText" type="file" multiple = "multiple" name = "file"  accept=".fit" hidden />
                <label id ="upload_label" for = "upload_file"> Choose File</label>
                <span id="file-chosen"  ref = "fileSelectedText">File Type: fit / Up to 5 Files</span>

                <div class="button-area" > 
                    <input id = "file_submit" type="submit" class = "submit" name = "submit" > 
                </div>
        </form>
    </div>

    <div id = "chart_area">
        <canvas ref = "lineChart" id="myChart" style = "width:100%; height:600px" ></canvas>
        <!-- <canvas id="myChartCadence" width="400" height="130" ></canvas> -->
    </div>

    <div id = "avg_power">
        
    </div>

    <div id="function_container">
        <button v-show = "exist_graph" class="function_button" id ="zoom" role="button" @click = "reset_zoom()">reset zoom</button>
        <div id = "function_button_area"> 
            <div v-for="(item, index) in device_info" :key = "index" style =" display: flex; gap: 15px">
                <button class="function_button offset-1 offset" :class = "item" @click = "offset_func($event)">{{item}} offset -1s</button>
                <button class="function_button offset+1 offset" :class = "item" @click = "offset_func($event)">{{item}} offset +1s</button>
                <form class="ChangeName" :class = "item" @submit.prevent="ChangeNameFunc($event)">
                    <label :for="item + 'ChangeName'"></label>
                    <input :id="item + 'ChangeName'" :type="'text'" :placeholder="item + ' change name'">
                    <input :type="'submit'" :value="'change'">
                </form>
                <span class="avg_power" :class = "item">{{item}} Avg Power: </span>
            </div>
        </div>
    </div>

    <div id = "footer_container">
        <footer id = "footer" style = "padding:0px">
                <!-- <div class="footer-content">
                    <h3>Mai's Power Comparison Tool</h3>
                    <div id ="term" @click = "func()">Terms and Privacy</div>
                    <ul class="socials">
                        <li><a href="https://github.com/MaiMMM"><i class="fa fa-github"></i></a></li>
                    </ul>
                </div> -->
                <div class="footer-bottom">
                    <p>copyright &copy;2022 Mai. designed by 
                        <router-link to= "/" style = "text-decoration: none;">
                            <span style = "color:white">Mai</span>
                        </router-link>
                        
                    </p>
                </div>
        </footer>
    </div>

    <div id="bg-model">

    </div>

    <v-dialog v-model="aboutShow" max-width="800px">
        <v-card>
            <v-card-title class="headline">About Power Meter Compare</v-card-title>
            <v-card-text>
                <PowerMeterCompareAbout />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="aboutShow = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>
  
<script>
import {demo_time_arr, demo_power_arr, demo_device_arr} from './demo.js';
import { shallowRef } from 'vue';
import FitParser from 'fit-file-parser';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
} from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import PowerMeterCompareAbout from './PowerMeterCompareAbout.vue';
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  zoomPlugin
);

  export default {
    name: 'PowerMeterCompare',
    // use components to import other vue files
    components: {
      PowerMeterCompareAbout
    },
    data() {
      return {
        upload_files : null, //original files from drop and drag
        PowerChart : null, // Chart Object
        CadenceChart : null,
        PowerSourceArray : [],
        myChart : null, // html Chart
        device_info : [],
        chart_obj_arr : [],
        offset_arr : [], //offset of each array, initialized to be all 0
        time_arr : [], //time_arr -> {{time of file1},{time of file2},{time of file3},.....}
        power_arr : [], //power_arr -> {{power of file1},{power of file2},{power of file3},.....}
        cadence_arr : [],
        original_data : null,
        exist_graph : false,
        time_arr_longest_index : null,
        demo_time_arr : demo_time_arr,
        demo_power_arr : demo_power_arr,
        demo_device_arr : demo_device_arr,
        colorArray : ['#FF6633', '#6666FF', '#FF33FF', '#B3B31A', '#00B3E6', 
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D','#80B300', 
        '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', 
        '#FF1A66', '#E6331A', '#33FFCC','#66994D', '#B366CC', '#4D8000', 
        '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', 
        '#1AB399','#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933','#FF3380', 
        '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', 
        '#FF4D4D', '#99E6E6', '#6666FF'],
        localData: null,
        aboutShow: false,
      }
    },
    created() {
    },
    mounted() {
        document.title = "PM Compare";
    },
    // onchange 
    watch: {
        upload_files: function(){

        }
    },
    methods:{
        //--------------------------------------------helper function---------------------------------------------------------
        //convert 2d unix time array to 2d formated js time array
        unixtime_to_formated(arr){
            var ret = [];

            for (var i = 0; i < arr.length; i++){
                var temp_arr = []
                for( var j = 0; j < arr[i].length; j++){
                temp_arr.push(new Date(j*1000).toISOString().slice(11, 19));
                }
                ret.push(temp_arr);
            }
            return ret;
        },
        remove_previous_graph(){
            //remove entire canvas
            this.PowerChart.destroy();

            //remove all data
            this.chart_obj_arr = [];
            this.PowerSourceArray = [];
            this.device_info = [];
            this.offset_arr = []; 
            this.time_arr = [];
            this.power_arr = [];

        },
         //fetch average power from selected area
        startFetch(){
            var avg_power_array = [];

            //helper function: find average of array
            function avg_arr(arr){
                var sum = arr.reduce((a, b) => a + b, 0);
                var avg = (sum / arr.length) || 0;

                return avg;
            }

            var {min, max} = this.PowerChart.scales.x;
            max = max += 1;
            for( let i = 0; i < this.PowerChart.data.datasets.length; i++){

                let p = this.PowerChart.data.datasets[i].data

                //if the line ends in between the zoom in section
                if(p.length < max && p.length >= min){
                avg_power_array.push("missing data");
                }
                //if the line ends before the zoom in section
                else if (p.length < min){
                avg_power_array.push("missing data");
                }
                // the line ends after the zoom in section
                else{
                var sliced_arr = p.slice(min,max);
                // console.log(sliced_arr);
                var avg_power = avg_arr(sliced_arr).toFixed(2).toString();
                avg_power_array.push(avg_power);

                }
            }

            // console.log(avg_power_array);
            //change innerhtml of average power elements
            var all_span = document.querySelectorAll('.avg_power');
            for(let i = 0; i < all_span.length; i++){
                all_span[i].innerHTML = this.device_info[i] + " Avg Power: " + avg_power_array[i];
            }
        },
        reset_zoom(){
            this.PowerChart.resetZoom();
        },
        offset_func(e){
            // offset function, used to change offset of any data line by updating graph
            // devide = devide name in string
            // direction = offset+1 or offset-1 or offset+0
            let classes = e.currentTarget.className;
            let device = classes.split(" ")[3];
            let direction = classes.split(" ")[1];

            if(this.offset_arr.length === 0){this.offset_arr.length = this.device_info.length; this.offset_arr.fill(0);}

            var index = this.device_info.findIndex(function(d){ return d === device; }); //index of the device in the device array

            if(direction === "offset+1"){this.offset_arr[index] += 1;}
            else if(direction === "offset-1"){this.offset_arr[index] -= 1;}
            //   else if(direction === "offset+0"){} // this is for recover offset during rename, or other operation that resets the offset
            else{alert("offset input wrong");}

            var offset = this.offset_arr[index];
            var p_arr = JSON.parse(JSON.stringify(this.original_data[index].data));//make a copy

            if(offset >= 1){  //push n element from beginning to their right, replace with empty slot with 0, remove n element from end 
                for(i = 0; i < offset; i++){
                if(p_arr.length >= this.time_arr[this.time_arr_longest_index].length){//remove end element only if the length exceeds time_arr.length 
                    p_arr.pop(); //remove end elements
                }
                p_arr.unshift(0);//add 0 to beginning
                }
            }
            else if(offset < 0){ //remove n element from beginning, add 0's to the end of the array
                for(var i = 0; i < Math.abs(offset); i++){ 
                p_arr.shift(); //remove end elements
                // p_arr.push(0);//add 0 to beginning
                }
            }
            // console.log(this.PowerChart.data.datasets[0].data)
            this.PowerChart.data.datasets[index].data = p_arr;
            // console.log(this.PowerChart);
            this.PowerChart.update();

            this.startFetch();
        },
        offset_func_by_para(device, direction){
            // offset function, used to change offset of any data line by updating graph
            // devide = devide name in string
            // direction = offset+1 or offset-1 or offset+0

            if(this.offset_arr.length === 0){this.offset_arr.length = this.device_info.length; this.offset_arr.fill(0);}

            var index = this.device_info.findIndex(function(d){ return d === device; }); //index of the device in the device array

            if(direction === "offset+1"){this.offset_arr[index] += 1;}
            else if(direction === "offset-1"){this.offset_arr[index] -= 1;}
            // eslint-disable-next-line
            else if(direction === "offset+0"){;} // this is for recover offset during rename, or other operation that resets the offset
            else{alert("offset input wrong");}

            var offset = this.offset_arr[index];
            var p_arr = JSON.parse(JSON.stringify(this.original_data[index].data));//make a copy

            if(offset >= 1){  //push n element from beginning to their right, replace with empty slot with 0, remove n element from end 
                for(i = 0; i < offset; i++){
                if(p_arr.length >= this.time_arr[this.time_arr_longest_index].length){//remove end element only if the length exceeds time_arr.length 
                    p_arr.pop(); //remove end elements
                }
                p_arr.unshift(0);//add 0 to beginning
                }
            }
            else if(offset < 0){ //remove n element from beginning, add 0's to the end of the array
                for(var i = 0; i < Math.abs(offset); i++){ 
                p_arr.shift(); //remove end elements
                // p_arr.push(0);//add 0 to beginning
                }
            }
            // console.log(this.PowerChart.data.datasets[0].data)
            this.PowerChart.data.datasets[index].data = p_arr;
            // console.log(this.PowerChart);
            this.PowerChart.update();

            this.startFetch();
        },
        //callback function from ChangeNameFunc
        change_name(current_name,new_name){
            console.log(current_name,new_name);
            var new_name_exist = false;

            for(var i = 0; i < this.device_info.length; i++){
                if(this.device_info[i] === new_name){
                alert("name already exits"); 
                new_name_exist = true; break;}
            }

            if(!new_name_exist){
                this.remove_previous_graph_changeName();
                for(i = 0; i < this.device_info.length; i++){
                // console.log(this.device_info[i]);
                // console.log(current_name);
                if(this.device_info[i] === current_name){
                    this.device_info[i] = new_name; break;
                    }
                }
                this.makegraph(this.time_arr,this.power_arr);

                for(i = 0; i < this.device_info.length;i++){
                    this.offset_func_by_para(this.device_info[i],"offset+0");
                }
            }
        },
        //directly called from html when button is hit
        ChangeNameFunc(e){
            // console.log(e);
            // console.log(this.offset_arr);
            var className_arr = e.currentTarget.className.split(" ");
            var new_name = document.getElementById(className_arr[1]+"ChangeName").value;

            //if contains space
            if(new_name.indexOf(' ') >= 0){alert("space not allowed");}
            //if empty
            else if(new_name === ""){alert("no input");}

            else{
                // new_name = DOMPurify.sanitize(new_name);
                this.change_name(className_arr[1],new_name);
            }
        },
        remove_previous_graph_changeName(){
            //remove entire canvas
            this.PowerChart.destroy();
            // document.getElementById("myChart").remove();     
            // let canvas = document.createElement('canvas');     
            // canvas.setAttribute('id','myChart');     
            // canvas.setAttribute('width','400');     
            // canvas.setAttribute('height','100');     
            // document.querySelector('#chart_area').appendChild(canvas);

            //remove all data
            this.chart_obj_arr = [];

            // //remove all reset button
            // var all_button = document.querySelectorAll('.function_button');
            // for(let i = 1; i < all_button.length; i++){
            //     all_button[i].remove();
            // }

            // var all_ChangeName = document.querySelectorAll('.ChangeName');
            // for(let i = 0; i < all_ChangeName.length; i++){
            //     all_ChangeName[i].remove();
            // }

            // var all_span = document.querySelectorAll('.avg_power');
            // for(let i = 0; i < all_span.length; i++){
            //     all_span[i].remove();
            // }
        },
        fitDataHandler(data){
            let tempPowerArr = [];
            let tempTimeArr = [];
            let tempDevice = data.activity.device_infos[0].manufacturer
            let laps = data.activity.sessions[0].laps;
            laps.forEach((lap)=>{
                lap.records.forEach((record)=>{
                    tempPowerArr.push(record.power);
                    tempTimeArr.push(record.timestamp.getTime()/1000);
                });
            })

            this.device_info.push(tempDevice);
            this.time_arr.push(tempTimeArr);
            this.power_arr.push(tempPowerArr);

        },
        //--------------------------------------------chart js---------------------------------------------------------
        makegraph(time_arr,power_arr){

            //time_arr -> {{time},{time}, ....}
            //power_arr -> {{power},{power},...}
            time_arr = this.unixtime_to_formated(time_arr);
            this.time_arr_longest_index = time_arr.reduce((p, c, i, a) => a[p].length > c.length ? p : i, 0); // this algorithm finds the longest time in time_arr
            //covert power_array to an array of dataset used in chart JS
            for(var i = 0; i < power_arr.length; i++){

            var temp_obj = {
                label: this.device_info[i],
                data:power_arr[i],
                borderColor: this.colorArray[i],
                fill:false,
                tension:0,
                borderWidth: 1.2
            };

            this.chart_obj_arr.push(temp_obj);
            }

            //creating chart using ChartJS
            this.myChart = this.$refs.lineChart.getContext('2d');
            this.PowerChart = shallowRef(
                new ChartJS(this.myChart,{
                type:'line', 
                data:{
                    labels:time_arr[this.time_arr_longest_index], 
                    datasets:this.chart_obj_arr,
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                    tooltip: { // hover box
                        mode: 'index', // show all data
                        intersect: false, // cursor doesn't need to be on top of the line
                        // titlecolor: "black",
                        // bodyColor:"black",
                        // footerColor:"black",
                        // borderColor:"black",
                        backgroundColor: 'rgba(140, 140, 140, 0.5)',
                        // displayColors: false,
                        
                    },
                    title: {display: false,text: 'Power Compare'},
                    zoom: {
                        zoom: {
                        drag:{
                            enabled: true, 
                            backgroundColor: "rgb(0, 128, 128,0.3)",
                            borderColor: "rgba(0,0,0,0)",
                            borderWidth: "0",
                            threshold: "1"
                        },
                        mode: 'x',
                        onZoomComplete: this.startFetch //get selected power elements
                        }
                    }
                    },
                    interaction: {
                    intersect: false,
                    },
                    scales: {
                    x: {
                        display: true,
                        title: {
                        display: true,
                        },
                        ticks:{ //x axis data (time)
                        maxTicksLimit:15, // how many data points in x axis
                        maxRotation: 0, // make the time data horizontal 
                        minRotation: 0, // same as above
                        labelOffset:10, // idk what this is
                        }
                    },
                    y: {
                        display: true,
                        title: {
                        display: true,
                        text: 'Power'
                        },
                        suggestedMin: 0,
                        suggestedMax: 500
                    }
                    },
                    elements:{
                        point:{
                            radius: 0
                        }
                    },
                }
            }));

            this.original_data = JSON.parse(JSON.stringify(this.PowerChart.data.datasets)); // to use: original_data[index].data --> array of power data

            // make reset button visible after graph is generated
            var buttons = document.getElementsByClassName("function_button");
            for(i = 0; i < buttons.length; i++){
            buttons[i].style.display = "block";
            }

            document.getElementById("zoom").style.display = "block";
            document.getElementById("footer").style.position = "relative";
            // document.getElementById("footer").style.buttom = "150px";

            //scroll to lower in the page
            var scrollheight = document.getElementById("myChart").getBoundingClientRect().top + window.pageYOffset - 25 ;
            window.scrollTo({ top: scrollheight, behavior: 'smooth' });

            this.$nextTick(() => {
                this.startFetch();
            });

        },
        //--------------------------------------------DEMO--------------------------------------------------------- 
        try_demo(){
            if(this.exist_graph){this.remove_previous_graph();}
            this.device_info = demo_device_arr;
            this.time_arr = demo_time_arr;
            this.power_arr = demo_power_arr
            this.makegraph(this.time_arr, this.power_arr);
            this.exist_graph = true;
        },
        // --------------------------------------------file upload---------------------------------------------------------
        changeFileText(){
            // Change the text of the file input to the name of the file
            const input = this.$refs.upload_file;
            const text = input.files.length > 1 ? `${input.files.length} files selected` : input.files[0].name;
            this.$refs.fileSelectedText.innerText = text;
        },

        async handleFileSubmit() {
            // Handle file submission event
            const input = this.$refs.upload_file;

            let submitSuccess = true;
            if(this.exist_graph){this.remove_previous_graph();}
            for(const file of input.files){
                let fileData = await this.readFileAsync(file);
                const parser = new FitParser({
                    force: true, speedUnit: 'km/h', lengthUnit: 'km',
                    temperatureUnit: 'kelvin', elapsedRecordField: true, mode: 'cascade',});
                try {
                    parser.parse(fileData, (error, data) => {
                        // Handle result of parse method
                        if (error) {console.log(error); submitSuccess = false; alert("Error parsing FIT file: " + error);} 
                        else {
                            this.fitDataHandler(data);
                        }
                    });
                } catch (error) {
                    console.error('Error parsing FIT file:', error);
                    submitSuccess = false;
                }
            } 
            
            if(submitSuccess){
                this.$nextTick(() => {
                    this.makegraph(this.time_arr, this.power_arr);
                });
                this.exist_graph = true;
                
            }
        },

        readFileAsync(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                // Set up event listeners for when the file is loaded
                reader.onload = (event) => {
                const fileData = event.target.result;
                resolve(fileData);
                };

                // Set up event listener for errors
                reader.onerror = (event) => {
                reject(event.target.error);
                };

                // Read the file as text or other formats based on your needs
                // For example, to read as text:
                // reader.readAsText(file);
                // To read as data URL:
                // reader.readAsDataURL(file);
                // To read as ArrayBuffer:
                // reader.readAsArrayBuffer(file);

                // For simplicity, let's read the file as text in this example
                reader.readAsArrayBuffer(file);
            });
        },
    }
  };
</script>

<style scoped>
    
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');

*{
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}

.PMCompare{
    font-family: 'Oswald', sans-serif;
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow-x: hidden;
}

#mai_logo_container{
  height: 300px;
  position: absolute;
}
#mai_logo{
  margin-top: -140px;
  margin-left: -30px;
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: 5;
}

@media screen and (max-width: 880px){
  #mai_logo_container{
    display: none;
  }
}

#header{
    position: static;
    z-index: 3;
    display: block;
    width: 100%;
    font-size: .8em;
    letter-spacing: 1px;
    padding: 10px 0;
    text-align: center;
    background: black;
    color: white;
}


#nav{
    position: relative;
    top:0;
    font-size: .8em;
    letter-spacing: 1px;
    margin-top: 20px;
    padding-bottom: 0px;
    text-align: center;
    background: white;
    color: black;
    display: flex;
    justify-content: center;
    z-index: 3;
    margin-bottom: 10px;

}

#option{
    width: 500px;
    height: 30px;
    background-color: #000000;
    border-radius: 13px;
    color:white;
}

#option > div{
    float:left;
    height: 30px;
    line-height: 30px;
    width: calc(100% / 2);  
    display: block;
}

#option > div:hover{
    background-color: rgb(156, 58, 58);
    border-radius: 13px;
    color:white;
    /* transition: all 0.1s ease-in; */
    /* color: black; */
}


#upload{
    text-align: center;
}


@media (min-width: 768px) {
  .offset {
    min-width: 170px;
  }
}

#postFit{
  text-align: center;
  border: 2px dashed transparent;
  border-color:"transparent";
  border-radius: 20px;
  width: 480px;
  margin-top: 0px;
  margin: auto;
  padding: 20px;
}



#file-chosen{
  margin-top: 0px;
  position: absolute;
  margin-left: 0.8rem;
  /* display: none; */
}

/* CSS */
.submit,#upload_label,#demo {
  background-color: white;
  border-radius: 0.3rem;
  margin-top: 20px;
  /* font-family: "Open Sans", sans-serif; */
  font-size: 12px;
  
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 1px 1px 0px 0px, 2px 2px 0px 0px, 2px 2px 0px 0px, 2px 2px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight:bold;
}

.submit:active,#upload_label:active,#zoom:active,#demo:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}



@media (min-width: 768px) {
  .submit,#upload_label,#demo {
    padding: 0.25em 0.75em;
  }
}
.button-area span{
  display:none;
}

#upload_label,#demo{
  font-weight: bolder;
}

#upload_label:hover,#demo:hover{
  background-color: #d3d0d0;
}

#demo{
  /* position: absolute; */
  margin-top: -1px;
  margin-left: 20px;

}

#chart_area{
  margin-top: 35px;
}

#function_container{
  margin-top: 50px;
  text-align: center;
}




#function_button_area{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-items: center;
  grid-gap: 20px;
  width: 940px;
  max-width: 100%;
  margin: auto;
  margin-top: 50px;
}


.function_button{
  display: block;
}

.function_button.offset,#zoom{
  
  font-family: 'Oswald', sans-serif;
  background-color: rgb(102, 102, 102);
  color:white;
  font-size: medium;
  border-radius: 10px;
  padding:20px;
  height: 10px;
  border-width: 0;
  margin-top: 4px;
  line-height: 0px;
}

#zoom{
  margin:auto;
  /* display: none; */
}

.function_button.offset:hover{
  transition: opacity .25s;
  opacity: 80%;
  cursor: pointer;
}
#zoom:hover{
  transition: opacity .25s;
  opacity: 80%;
  cursor: pointer;
}


input[type=text]{
  font-family: 'Oswald', sans-serif;
  font-size:medium;
  padding-left: 50px;
  width:200px;
  padding:7px;
  margin:5px;
  border-radius:10px;
  border-width: thin;
  box-shadow:4px 4px 10px rgba(0,0,0,0.06);
  margin-right:0px
}

.ChangeName{
  /* margin-top: 3px; */
  width: 200px;
  margin-left: 15px;
}

input[value="change"]{
  display:none;
}

.avg_power{
  text-align: left;
  width: 200px;
  margin-left:25px;
  margin-top:15px;
}

#myChart{
  display: none;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300&display=swap');
#footer_container{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}
#footer_container{
    background: #fcfcfc;
}
footer{
    position:absolute;
    left: 0;
    right: 0;
    background: #111;
    height: 1143.64;
    width: 100%;
    bottom: 0;
    margin-top: 150px;
    /* font-family: "Open Sans"; */
    padding-top: 30px;
    color: #fff;
}
.footer-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height:90px
}
.footer-content h3{
    margin-top: -6.5px; /* how far Mai's powe meter from top*/
    font-size: 1.8rem;
    font-weight: 400;
    /* line-height: 3rem; */
    margin-bottom: 10px;
}
.footer-content p{
    max-width: 500px;
    margin: 10px auto;
    line-height: 28px;
    font-size: 14px;
}
.socials{
    position: absolute;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1 rem 0 2rem 0; */
    margin-top: 30px;
    margin-left: 340px;
}
.socials li{
    margin: 0 10px;
}
.socials a{
    text-decoration: none;
    color: #fff;
}
.socials a i{
    font-size: 1.1rem;
    transition: color .4s ease;

}
.socials a:hover i{
    color: rgb(11, 15, 15);
}

.footer-bottom{
    background: #000;
    width: 100%;
    padding: 1px 0;
    text-align: center;
}
.footer-bottom p{
    font-size: 14px;
    word-spacing: 2px;
    text-transform: capitalize;
}
.footer-bottom span{
    text-transform: uppercase;
    opacity: .4;
    font-weight: 200;
}

#term{
    cursor: pointer;
}

#bg-model{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top:0;
    z-index: 1000;
    transition: all 0.5s ease-in-out;
    justify-content: center;
    align-items: center;
    display: none;
}

#model-content{
    position: absolute;
    width: 500px;
    height: 300px;
    background-color: white;
    z-index: 1001;
    border-radius: 4px;
    overflow-y: scroll;
    text-align: center;
    padding: 0 5px 0 5px;
}
</style>
